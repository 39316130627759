import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {
    constructor(private http: Http) {}

    getCountryList() {
        var url = "/api/country-list"

        return this.http.get(url)
            .map(res => res.json())
    }

    getOrganizationType() {
        var url = "/api/organization-type"

        return this.http.get(url)
            .map(res => res.json())
    }

    register(user) {
        var url = "/api/register"

        return this.http.post(url, user)
            .map(res => res.json())
    }

    login(user) {
        var url = "/api/login"

        return this.http.post(url, user)
            .map(res => res.json())
    }
    
    authorize(token) {
        var url = "/api/authorize?token="+token
 
        return this.http.get(url)
            .map(res => res.json())
    }

    getGRIDList() {
        var url = "/api/grid-list"

        return this.http.get(url)
            .map(res => res.json())
    }

    submitAbstract(abstract) {
        var url = "/api/submit-abstract"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, abstract, options)
            .map(res => res.json())
    }

    updateProfile(profile) {
        var url = "/api/update-profile"

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.post(url, profile, options)
            .map(res => res.json())
    }

    getSubmittedAbstracts(userId) {
        var url = "/api/submitted-abstracts/"+userId

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getAbstractById(userId) {
        var url = "/api/abstract/"+userId

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }
}