import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../shared/user.service';
import { ActivatedRoute, Router, Params } from '@angular/router';

import swal from 'sweetalert2';

@Component({
  selector: 'app-view-abstract',
  templateUrl: './view-abstract.component.html',
  styleUrls: ['./view-abstract.component.css']
})
export class ViewAbstractComponent implements OnInit {

  public user: any = {}
  public profile: any = {}

  public abstract: any = {}
  
  @ViewChild('closeProfileModel') closeProfileModel:ElementRef;

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem("user") === null) window.location.href = 'https://onixhub.com'

    this.user = JSON.parse(localStorage.getItem('user'))

    this.profile = {
      _id: this.user._id,
      first: this.user.name.first,
      middle: this.user.name.middle,
      last: this.user.name.last,
      phone: this.user.phone
    }

    this.route.params.subscribe((params: Params) => {
      var id = params['id']
      
      this.userService.getAbstractById(id).subscribe(
        data => {
          if(data.status) {
            this.abstract = data.abstract
          } else {
            window.location.href = 'https://onixhub.com/login.html'
          }
        }
      )
    })
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        window.location.href = 'https://onixhub.com'
      }
    })
  }

  resetProfile() {
    this.profile.first = this.user.name.first
    this.profile.middle = this.user.name.middle
    this.profile.last = this.user.name.last
    this.profile.phone = this.user.phone
  }

  sessionTimeOut() {
    localStorage.clear()
    window.location.href = 'https://onixhub.com/login.html?sessionTimeout=true'
  }

  updateProfile() {
    this.userService.updateProfile(this.profile).subscribe(
      data => {
        if(data.success || data.success == false) this.sessionTimeOut()
        if(data.status) {
          let messageText = 'Your Profile has been Updated Successfully!'
          swal({
            title: messageText,
            type: 'success',
            confirmButtonText: 'Okay'
          })
          this.user.name.first = this.profile.first
          this.user.name.middle = this.profile.middle
          this.user.name.last = this.profile.last
          this.user.phone = this.profile.phone
          localStorage.setItem('user', JSON.stringify(this.user))
          this.closeProfileModel.nativeElement.click();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

}
