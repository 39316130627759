import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserService } from '../shared/user.service';

import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: any = {}

  invalidLogin: boolean = false;

  constructor(private renderer: Renderer2, private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    if (localStorage.getItem("user") != null) this.router.navigate(['dashboard'])
  }

  signIn() {
    this.login.userType = 'U'
    this.userService.login(this.login).subscribe(
      data => {
        localStorage.setItem('user', JSON.stringify(data[0]))
        if(data.length > 0) 
          this.router.navigate(['dashboard']);
        else 
          this.invalidLogin = true
      }
    );
  }
}
