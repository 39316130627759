import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { Select2Module } from 'ng2-select2';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';


import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { ViewAbstractComponent } from './view-abstract/view-abstract.component';
import { NewAbstractComponent } from './new-abstract/new-abstract.component';

// Define the routes
const routes:Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'view-abstract/:id',
    component: ViewAbstractComponent
  },
  {
    path: 'new-abstract',
    component: NewAbstractComponent
  },
  {
    path: 'update-abstract/:id',
    component: NewAbstractComponent
  },
  {
    path: 'authorize/:token',
    component: AuthorizeComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    AuthorizeComponent,
    NewAbstractComponent,
    ViewAbstractComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    SharedModule,
    HttpModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    Select2Module,
    PasswordStrengthBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
