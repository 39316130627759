import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../shared/user.service';

import swal from 'sweetalert2';
import { Router } from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  register: any = {
    "name": {},
    "address": {},
    "phone": {},
    "contact": []
  }

  yearOfLastDegree: any = ['']
  prefixes: any = ['', 'Dr.', 'Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Prof.']
  countries: any;
  organizationTypes: any = [];

  @ViewChild('registerForm') registerForm:ElementRef;

  public barLabel: string = "Password strength:";
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public baseColor = '#555'
  public strengthLabels = ['Worst', 'Bad', 'Medium', 'Good', 'Best']

  constructor(private renderer: Renderer2, private userService: UserService, private router: Router) {
  }

  ngOnInit() {

    if (localStorage.getItem("user") != null) this.router.navigate(['dashboard'])

    let currentYear = (new Date().getFullYear())

    for(let year = currentYear; year > (currentYear - 50); year--) {
      this.yearOfLastDegree.push(year)
    }

    this.register.organizationType = ''
    this.register.yearOfFinalDegree = ''
    this.register.name.prefix = ''
    this.register.address.country = ''
    this.userService.getCountryList().subscribe(
      countries => {
        this.countries = countries
        this.countries.unshift({name:'Choose...', code: ''})
      }
    )
    this.userService.getOrganizationType().subscribe(
      organizationType => {
        this.organizationTypes = organizationType
        this.organizationTypes.unshift({type:'Choose...', typeId: ''})
      }
    )
  }

  signup() {
    this.register.userType = 'U'
    this.userService.register(this.register).subscribe(
      data => {
        if(data.status) {
          
          this.register = {
            "name": {},
            "address": {},
            "phone": {},
            "contact": []
          }
          this.register.organizationType = ''
          this.register.yearOfFinalDegree = ''
          this.register.name.prefix = ''
          this.register.address.country = ''
          this.registerForm.nativeElement.reset();

          swal({
            title: 'Registered Successfully!',
            type: 'success',
            confirmButtonText: 'Okay'
          }).then((result) => {
            if (result.value) {
              this.router.navigate([''])
            }
          })
          
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  addContact() {
    this.register.contact.push({})
  }

  deleteContact(contactIndex) {
    this.register.contact.splice(contactIndex, 1)
  }
}
